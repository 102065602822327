import React from "react";
import { graphql, withPrefix } from "gatsby"
import SEO from "../components/SEO";
import Layout from "../layouts/index";

const Terms = props => {
  const { title, file } = props.data.markdownRemark.frontmatter;
  const { html } = props.data.markdownRemark;
  return (
    <Layout bodyClass="page-aszf" path={props.location.pathname}>
      <SEO title={title} />
      <div className="container pt-4 pt-md-10">
        <div className="row justify-content-start">
          <div className="col-12">
            <div className="service service-single">
              {file ? (
                <div className="text-right w-100 pt-3">
                  <a href={withPrefix(file)}>Letöltés</a>
                </div>
              ) : null}
              <h1 className="title">{title}</h1>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        path
        type
        file
      }
      html
    }
  }
`;


export default Terms;